import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react'
import BlogCard from './BlogCard';



const Blog = ({props}) => {
  const { posts, errors } = props
  console.log(posts)
  if (errors) {
    console.log('====================================');
    console.log(errors);
    console.log('====================================');
  }
    return (
      <div className="flex flex-col px-8 xl:px-32 pt-32 pb-8 bg-gray-350 ">
        <div className="flex flex-col lg:flex-row">
          <div className="lg:w-1/3 md:h-80">
            <img
              src={posts && posts.edges[1].node.mainImage.asset.url}
              className="md:h-80"
              alt={posts && posts.edges[1].node.title}
            ></img>
          </div>
          <div className="lg:w-2/3 text-white  flex flex-col bg-black py-4 px-8">
            <p className="text-sm font-thin">Blog</p>
            <h1 className="text-4xl lg:text-7xl pb-16 pt-4 ">
              {posts && posts.edges[1].node.title}
            </h1>
            <a
              href={`/blog/${posts && posts.edges[1].node.slug.current}`}
              className="text-sm font-thin"
            >
              read more
            </a>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row py-8 justify-between">
          <div className="lg:w-72 xl:w-96 ">
            {" "}
            <BlogCard
              title={posts && posts.edges[0].node.title}
              link={`/blog/${posts && posts.edges[0].node.slug.current}`}
            >
              <img
                src={posts && posts.edges[0].node.mainImage.asset.url}
                className="md:h-80"
                alt={posts && posts.edges[0].node.title}
              ></img>
            </BlogCard>
          </div>
          <div className="lg:w-72 xl:w-96 ">
            <BlogCard
              title={posts && posts.edges[2].node.title}
              link={`/blog/${posts && posts.edges[2].node.slug.current}`}
            >
              <img
                src={posts && posts.edges[2].node.mainImage.asset.url}
                className="md:h-80"
                alt={posts && posts.edges[2].node.title}
              ></img>
            </BlogCard>
          </div>
          <div className="lg:w-72 xl:w-96 ">
            <BlogCard
              title={posts && posts.edges[3].node.title}
              link={`/blog/${posts && posts.edges[3].node.slug.current}`}
            >
              <img
                src={posts && posts.edges[3].node.mainImage.asset.url}
                className="md:h-80"
                alt={posts && posts.edges[3].node.title}
              ></img>
            </BlogCard>
          </div>
        </div>
      </div>
    )
}


export default Blog