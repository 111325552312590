import { StaticImage } from "gatsby-plugin-image";
import React from "react"
import Card from "./Card";

const WhatWeDo = () => {
  return (
    <>
      <div
        data-aos-anchor-placement="top-center"
        id="services"
        className="flex flex-col px-6 lg:px-16 py-16 bg-teal-500 text-black bg-white font-semibold"
      >
        <div
          data-aos="fade-up"
          data-aos-offset="10"
          data-aos-delay="2"
          data-aos-duration="500"
          data-aos-once="true"
          className="flex justify-center py-6 lg:pb-16"
        >
          <h1 className="text-6xl font-bold ">What we do</h1>
        </div>

        <div
          data-aos="fade-up"
          data-aos-offset="10"
          data-aos-delay="2"
          data-aos-duration="500"
          data-aos-once="true"
          // data-aos-anchor-placement="top-center"
          className=" grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3   items-center justify-between"
        >
          <div className=" xl:w-72 ">
            <Card title="Insight-driven Media Planning" link="/">
              <StaticImage
                alt="insight driven media planning"
                src="../images/planning-insight-media-seal.png"
                placeholder="blurred"
                className="rounded-t-xl"
              />
            </Card>
          </div>
          <div className=" xl:w-72 ">
            <Card title="Compliance and Data Management" link="/">
              <StaticImage
                alt="compliance and data management"
                src="../images/about/compliance2.png"
                placeholder="blurred"
                className="rounded-t-xl"
              />
            </Card>
          </div>
          <div className=" xl:w-72 ">
            <Card title="Buying Optimization" link="/">
              <StaticImage
                alt="Buying Optimization"
                src="../images/Buying (1).png"
                placeholder="blurred"
                className="rounded-t-xl"
              />
            </Card>
          </div>
          <div className=" xl:w-72 ">
            <Card title="End-to-End Digital Media Solutions" link="/">
              <StaticImage
                alt="digital media innovation"
                src="../images/Digital_1.jpeg"
                placeholder="blurred"
                className="rounded-t-xl"
              />
            </Card>
          </div>

          <div className=" xl:w-72 ">
            <Card title="Media Relations" link="/">
              <StaticImage
                alt="media relations"
                src="../images/MR (2).png"
                className="rounded-t-xl"
              />
            </Card>
          </div>
          <div className=" xl:w-72 ">
            <Card title="Research & Media Information Resources " link="/">
              <StaticImage
                alt="research and media information"
                src="../images/Resources (1).png"
                placeholder="blurred"
                className="rounded-t-xl"
              />
            </Card>
          </div>
          <div className=" xl:w-72 ">
            <Card title="Marketing Consulting" link="/">
              <StaticImage
                alt="marketing consulting"
                src="../images/Consultancy (1).png"
                placeholder="blurred"
                className="rounded-t-xl"
              />
            </Card>
          </div>
          <div className=" xl:w-72 ">
            <Card title="BTL & Activation" link="/">
              <StaticImage
                alt="BTL & Actvation"
                src="../images/about/btl.png"
                placeholder="blurred"
                className="rounded-t-xl"
              />
            </Card>
          </div>
        </div>
      </div>
    </>
  )
}

export default WhatWeDo
