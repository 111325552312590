import React, { useEffect } from "react"
import HeroBG from "../images/bg_hero.svg"
import Button from "./Button";
import lottie from 'lottie-web'
import animation from '../../static/animation.json'

const Hero = () => {
  const animationRef = React.createRef()

  useEffect(()=>{
    const anim = lottie.loadAnimation({
      container: animationRef.current,
      animationData: animation,
      loop: false,
      autoplay: true,
    })
  },[])

  const parallax = {
    /* Set a specific height */
  Height: '300px',
  position: 'relative',
  /* Create the parallax scrolling effect */
  backgroundAttachment: 'fixed',
  backgroundPosition: 'left',
  backgroundSize: 'cover'
  }

  return (
    <>
      <div
        className="flex flex-col md:flex-row items-center justify-between pt-24  pb-24 md:pt-24 md:pb-16 lg:pb-32 px-4 bg-teal-500 text-black font-semibold "
        // style={{
        //   background: `url(${HeroBG}) no-repeat`,
        //   height: "40rem",
        //   ...parallax,
        // }}
      >
        <div className="w-full md:w-1/3 lg:w-1/3  lg:px-12 ">
          <div
            className="sm:px-2 "
            data-aos="fade-in-up"
            data-aos-duration="1500"
            data-aos-once="true"
          >
            <h1
              className=" pt-5 text-4xl text-left 
             font-extrabold lg:text-5xl xl:text-7xl md:w-72 lg:w-96 xl:w-100"
            >
              We connect consumers and brands
            </h1>
            <p className="pb-6 mt-8 text-lg text-gray-700 font-light md:w-72 lg:w-96 xl:w-100 sm:mt-8">
              through data-driven, consumer-inspired media solutions.
            </p>

            <Button action="Talk to us" link={'#contact'} />
          </div>
        </div>
        <div
          className="w-full md:w-2/3 lg:w-2/3 animation-container hidden md:flex md:flex-row"
          ref={animationRef}
        ></div>
      </div>
    </>
  )
}

export default Hero
