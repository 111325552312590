import React from 'react'
import { navigate } from "gatsby-link"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

function Contact() {

  const [state, setState] = React.useState({})

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const resetForm = () => {
    
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => { navigate(form.getAttribute("action")) })
      .catch(error => alert(error))
  }

    return (
      <div
        id="contact"
        className="flex flex-row justify-center items-center bg-black pt-24 pb-32 px-4 lg:px-16 "
      >
        <form
          name="contact"
          method="post"
          action="/"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
          className="flex flex-col justify-center lg:w-100"
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="contact" />
          <p hidden>
            <label>
              Don’t fill this out:{" "}
              <input name="bot-field" onChange={handleChange} />
            </label>
          </p>
          <h1 className="font-bold text-5xl text-white text-center py-4">
            Contact Us
          </h1>
          <p className="font-sm text-white text-center pb-16 font-thin">
            Complete the form or email mediaseal@mediaseal-ng.com
          </p>

          <input
            name="name"
            placeholder="Name"
            type="text"
            className="border-0 border-b-1 border-gray-850 text-gray-850 bg-transparent mb-12"
            onChange={handleChange}
          />
          <input
            name="email"
            placeholder="Email"
            type="email"
            className="border-0 border-b-1 border-gray-850 text-gray-850 bg-transparent mb-12"
            onChange={handleChange}
          />
          <input
            name="message"
            placeholder="Briefly tell us about your project"
            type="text"
            className="border-0 border-b-1 border-gray-850 text-gray-850 bg-transparent mb-12"
            onChange={handleChange}
          />

          <button
            role="button"
            className="flex bg-white py-2 justify-center px-8 text-sm text-black"
          >
            SEND MESSAGE
          </button>
        </form>
      </div>
    )
}

export default Contact
