import React from "react"
// import Audience from "../images/audience.png"
import { StaticImage } from "gatsby-plugin-image"
import Button from "./Button";

const CaseStudies = () => {
  return (
    <>
      <div className="flex flex-col justify-between py-16 lg:pt-32 bg-gray-50">
        <div className="flex justify-center px-4 lg:px-16   pb-16">
          <h1 className="text-6xl font-bold">Case Studies</h1>
        </div>

        <div className="flex flex-col-reverse lg:flex-row ">
          <div className="flex flex-col text-black px-4 lg:px-16 pt-12  lg:w-1/2  pb-12">
            <h1 className="text-3xl lg:text-4xl pb-8 ">MALTINA DANCE ALL</h1>

            <p className="lg:text-lg font-light pb-8">
              Maltina Dance All Season VI had succeeded to be one of the best
              family dance reality show in Nigeria.
            </p>

            <p className="lg:text-lg font-light pb-8">
              Season 6 in particular had a huge family and viewer engagement,
              with an estimated 22 million viewers and 4,000+ participants
              nationwide. Brand awareness increased significantly, with the
              Facebook page growing from 40,000 to 100,000+ followers at the end
              of the campaign.
            </p>

            <Button action="Learn more" link="/projects/maltina" />
          </div>

          <div className=" lg:w-1/2">
            <StaticImage
              placeholder="blurred"
              src="../images/image.png"
              alt="Maltina Dance All - Media Seal WPI"
              style={{
                height: 450,
              }}
            />
          </div>
        </div>

        <div className="flex flex-col lg:flex-row  ">
          <div className="flex lg:w-1/2">
            <StaticImage
              placeholder="blurred"
              src="../images/image (2).png"
              alt="Samsung - Media Seal WPI"
              style={{
                height: 450,
              }}
            />
          </div>

          <div className="flex flex-col text-black  px-4 lg:px-16 py-12   lg:w-1/2">
            <h1 className="text-3xl lg:text-4xl pb-8 ">
              BET KING AWARENESS CAMPAIGN
            </h1>

            <p className="lg:text-lg font-light pb-8">
              User traffic grew by 67.3% within the first month of the campaign,
              delivering a 45.3% conversion rate increase.
            </p>

            <p className="lg:text-lg font-light pb-8">
              The customer base skyrocketed, recording a remarkable growth from
              200 active customers before brand launch in 2017 to 300,000+
              active customers by the end of 2018.
            </p>

            <Button action="Learn more" link="/projects/bet-king" />
          </div>
        </div>

        <div className="flex flex-col lg:flex-row ">
          <div className="flex flex-col text-black px-4 lg:px-16 pt-24  w-full items-center justify-center lg:pb-0 pb-12">
            <a href="/projects">
              {" "}
              <h1 className="text-xl lg:text-3xl pb-8  underline">SEE MORE</h1>
            </a>

            {/* <Button action="Learn more" link="/projects" /> */}
          </div>

          {/* <div className="flex lg:w-1/2">
            <StaticImage
              placeholder="blurred"
              src="../images/image (2).png"
              alt="Maltina Dance All - Media Seal WPI"
            />
          </div> */}
        </div>
      </div>
    </>
  )
}

export default CaseStudies
