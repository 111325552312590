import { StaticImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import React, { useEffect } from "react"
import Blog from "../components/ Blog";
import AudienceAnalytics from "../components/AudienceAnalytic"
import CaseStudies from "../components/CaseStudies"
import Clients from "../components/Clients";
import Contact from "../components/Contact"
import Footer from "../components/Footer"
import Hero from "../components/Hero"
import Layout from "../components/Layout"
import Nav from "../components/Nav";
import WhatWeDo from "../components/WhatWeDo"
import Words from "../components/WordsOnTheStreet"
// import Danfo from "../images/danfo.png";
import Pattern from "../images/pattern.svg"
import AOS from "aos"
import "aos/dist/aos.css" // You can also use <link> for styles
import SEO from "../components/SEO";
// ..

export const query = graphql`
  query LandingPageQuery {
    posts: allSanityPost {
      edges {
        node {
          mainImage {
            asset {
              url
            }
          }
          _id
          author {
            _rawImage
            _rawBio
          }
          _createdAt
          _rawBody
          title
          slug {
            current
          }
          publishedAt
        }
      }
    }
  }
`

export default (props) => {

  const { data, errors } = props
 
  if (errors) {
    console.log("====================================")
    console.log(errors)
    console.log("====================================")
  }
   useEffect(()=>{
   AOS.init()
   }, [])

  return (
    <Layout>
      <SEO lang="en" keywords={["branding", "social media"]} />
      <main className="flex flex-col">
        <Hero />
        <div
          data-aos-anchor-placement="top-center"
          className="flex flex-col lg:flex-row justify-between hero px-4 lg:px-16 py-16 lg:py-20 xl:py-32 bg-blue-850 "
          style={{ background: `#004A97 url(${Pattern})` }}
        >
          <div
            data-aos="fade-in-up"
            data-aos-duration="1500"
            data-aos-once="true"
            data-aos-offset="0"
            data-aos-delay="0"
            className="block"
          >
            <StaticImage
              placeholder="blurred"
              // layout="fullWidth"
              src="../images/danfo.png"
              alt="Lagos Danfo Media Seal WPI"
            />
          </div>

          <div
            data-aos="fade-in-up"
            data-aos-duration="1500"
            data-aos-once="true"
            data-aos-offset="0"
            data-aos-delay="0"
            // data-aos-anchor-placement="top-center"
            className="flex flex-col text-white px-2 lg:pl-12 xl:px-16 lg:w-140"
          >
            <h1 className="text-4xl pb-8  lg:pt-0 pt-8 font-bold">
              Globally Connected <br />
              Locally Relevant
            </h1>

            <p className="text-lg font-light pb-8">
              As a foremost media specialist in Nigeria, we have a global
              network reputation to match, with 2 decades of cross-cultural,
              multi-market experience.
            </p>

            <p className=" text-lg font-light pb-8">
              We are committed to deploying superior talents and systems to
              impact brands and consumers, using global standards and local
              intelligence.
            </p>

            <a
              href="/about"
              className="text-white text-sm font-bold border-b-2 border-orange-1200 w-24"
            >
              See our story
            </a>
          </div>
        </div>

        <WhatWeDo />

        <AudienceAnalytics />

        <CaseStudies />

        {/* <Words /> */}

        <Clients />

        <Blog props={data}/>

        <Contact />
      </main>
    </Layout>
  )
}
