import React from "react"
// import Audience from "../images/audience.png"
import { StaticImage } from "gatsby-plugin-image"

const AudienceAnalytics = () => {
  return (
    <>
      <div
        data-aos-anchor-placement="top-center"
        className="flex flex-col lg:flex-row justify-between  px-4 lg:px-16 py-16 lg:py-32 bg-black"
      >
        <div
          className="block"
          data-aos="fade-up"
          data-aos-offset="50"
          data-aos-delay="20"
          data-aos-duration="500"
          data-aos-once="true"
        >
          <StaticImage
            // layout="fullWidth"
            placeholder="blurred"
            src="../images/audience.png"
            alt="Audience Analytics - Offline and Online - Media Seal WPI"
          />
        </div>

        <div
          data-aos="fade-up-right"
          data-aos-offset="50"
          data-aos-delay="20"
          data-aos-duration="500"
          data-aos-once="true"
          // data-aos-anchor-placement="top-center"
          className="flex flex-col text-white px-4 lg:pt-0 pt-8 lg:w-100 xl:px-16 xl:w-1/2"
        >
          <h1 className="text-4xl pb-8 font-bold">
            Audience Analytics: <br /> Offline + Online
          </h1>

          <p className="lg:text-lg font-light pb-8">
            Merging offline and online consumer behavior used to be almost
            impossible, but with the growth of mobile devices and recent
            technologies available to us, this gap has been bridged.
          </p>

          <p className="lg:text-lg font-light pb-8">
            The words “digital”, “programmatic”, “data”, etc now apply to
            offline media as much as online.
          </p>

          <a
            href="/audience-analytics"
            className="text-white text-sm font-bold border-b-2 border-orange-1200 w-20"
          >
            Learn more
          </a>
        </div>
      </div>
    </>
  )
}

export default AudienceAnalytics
